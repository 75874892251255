import React from 'react';
import styled from '@emotion/styled';

import Text from '../../Text';
import opensourceImage from '../../../assets/product/opensource-hero.svg';
import DefaultGridSection from '../DefaultGridSection';
import SlideDownAnchor from '../../SlideDownAnchor';

const SpacingContainer = styled('div')`
  margin-top: 9rem;
`;

const HeaderSection = () => {
  return (
    <SpacingContainer>
      <DefaultGridSection
        image={opensourceImage}
        direction="imageOnRight"
        title="Design & Development"
        subtitle="Build trust between any device, any service, anywhere"
      >
        <Text>
          We help you manage all your computing needs. We offer you managemet for onsite and cloud based data & computing devices.
          We help design a setup for your needs that lower costs and also increase your organizational security. We design custom solutions for your 
          electronic data security systems. 
          <Text color="caption">
          Please contact us with any questions:)
          </Text>
          
        </Text>
        {/* <SlideDownAnchor to="#sdk" /> */}
      </DefaultGridSection>
    </SpacingContainer>
  );
};

HeaderSection.propTypes = {};

export default HeaderSection;
