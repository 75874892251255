import React from 'react';
import styled from '@emotion/styled';

import HeaderSection from '../../components/pages/services/HeaderSection';
import SEO from '../../components/SEO';
// import CloudSdkSection from '../../components/pages/services/CloudSdkSection';
// import EdgeSdkSection from '../../components/pages/services/EdgeSdkSection';
// import SectionArrowSeparator from '../../components/pages/services/SectionArrowSeparator';
// import EmbeddedSdkSection from '../../components/pages/services/EmbeddedSdkSection';
// import GetStartedSection from '../../components/pages/GetStartedSection';
import ConfigurationsSection from '../../components/pages/services/analysis/ConfigurationsSection';


const SpacingContainer = styled('div')`
  margin-top: 6rem;
`;

const Index = () => {
  return (
    <>
      <SEO
        title="Organism | Services"
        description="Organism Systems offers a range of services for our clients."
      />
      <HeaderSection />
      <ConfigurationsSection />
      {/* <SectionArrowSeparator /> */}
      {/* <EdgeSdkSection />
      <SectionArrowSeparator />
      <EmbeddedSdkSection />
      <SpacingContainer>
        <GetStartedSection />
      </SpacingContainer> */}
    </>
  );
};

export default Index;
